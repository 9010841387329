import { memo } from "react";

const defaultProps = {
  width: 24,
  height: 24,
  isCircle: false,
};

const SvgFilter = ({ width, height, isCircle }: typeof defaultProps) => (
  <svg width={width} height={height} viewBox="0 0 24 23" fill="none">
    <g clip-path="url(#clip0_5324_19837)">
      <path
        d="M2.5 6.5H18.5M5.5 12.5H15.5M9.5 18.5H11.5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    {isCircle && (
      <circle cx="18.5" cy="5.5" r="4.5" fill="#34D6F1" stroke="#0E0B1A" />
    )}
    <defs>
      <clipPath id="clip0_5324_19837">
        <rect x="0.5" y="2.5" width="20" height="20" rx="4" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

SvgFilter.defaultProps = defaultProps;

export default memo(SvgFilter);
