import React from "react";
import { CenterView } from "../packages";
import ResponsiveDrawer from "./ResponsiveDrawer";
import { Outlet } from "react-router-dom";
import { RootState } from "../redux/store";
import { connect } from "react-redux"; // Import connect for Redux
import { UserDetails } from "../modules/ProfileModule/store/profile.types"; // Import UserDetails type
import { GetSubscriptionsAllPlans } from "../modules/BillingModule/store/billing.types";

const mapStateToProps = (state: RootState) => {
  const { getUserReducers, getSubscriptionsAllPlansReducers } = state;
  return {
    data: getSubscriptionsAllPlansReducers.data,
    userDetails: getUserReducers.userDetails, // Get userDetails from Redux
  };
};

type Props = {
  isNav?: boolean;
  userDetails: UserDetails; // Add userDetails as a prop
  data: GetSubscriptionsAllPlans;
};

const Layout = React.memo(({ isNav, userDetails, data }: Props) => {
  const isPlan = userDetails?.userPlan === "free"; // Check if the user is on a free plan
  console.log("isPlan", userDetails, isPlan, !userDetails?._id);

  return (
    <>
      {isNav ? (
        <CenterView>
          <ResponsiveDrawer>
            <Outlet />
          </ResponsiveDrawer>
        </CenterView>
      ) : (
        <CenterView>
          <Outlet />
        </CenterView>
      )}
    </>
  );
});

export default connect(mapStateToProps)(Layout);
