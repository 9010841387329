import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getUserApi, uploadProfileApi } from "../../../routes/apiRoutes";
import { GET_USER, UPDATE_USER, UPLOAD_PROFILE } from "../../../redux/actions";

export const getUserMiddleWare = createAsyncThunk(GET_USER, async (_a) => {
  try {
    const { data } = await axios.get(getUserApi);
    console.log('get user api ----- ', data);
    
    return data;
  } catch (error: any) {
    const typedError = error as Error;
    return typedError;
  }
});

export const updateUserMiddleWare = createAsyncThunk(
  UPDATE_USER,
  async ({
    firstName,
    lastName,
    phoneNumber,
    bodyWeight,
    bodyHeight,
    dateOfBirth,
    showDefaultPaymentsScreen,
    profilePicture,
  }: {
    firstName?: string;
    lastName?: string;
    phoneNumber?: {
      extension: string;
      number: string;
    };
    bodyWeight?: {
      weight: number;
      unit: string;
    };
    bodyHeight?: {
      height: any;
      unit: string;
    };
    dateOfBirth?: string;
    showDefaultPaymentsScreen?: boolean;
    profilePicture?: string;
  }) => {
    try {
      const { data } = await axios.patch(getUserApi, {
        firstName,
        lastName,
        phoneNumber,
        bodyWeight,
        bodyHeight,
        dateOfBirth,
        showDefaultPaymentsScreen,
        profilePicture,
      });
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const uploadProfileMiddleWare = createAsyncThunk(
  UPLOAD_PROFILE,
  async ({ formData }: { formData: any }) => {
    try {
      const { data } = await axios.post(uploadProfileApi, formData, {
        headers: {
          "Content-Type": "multipart/form-data; ",
        },
      });
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);
