import { memo } from "react";

const defaultProps = {
    width: 12,
    height: 14,
};

const SvgGuarantee = ({ width, height }: typeof defaultProps) => (
    <svg width={width} height={height} viewBox="0 0 12 14" fill="none">
        <path d="M7.49844 4.73137L7.16434 4.39728C7.01908 4.25202 6.78182 4.25202 6.63656 4.39728L4.97334 6.05808L4.39956 5.4843C4.2543 5.33904 4.01704 5.33904 3.87178 5.4843L3.53769 5.8184C3.39243 5.96366 3.39243 6.20092 3.53769 6.34618L4.70945 7.51794C4.85471 7.6632 5.09197 7.6632 5.23723 7.51794L7.49844 5.25673C7.6437 5.11147 7.6437 4.87421 7.49844 4.72895V4.73137Z" fill="#091423" />
        <path d="M10.6821 4.89361L10.1034 4.31499C9.88312 4.09468 9.75723 3.79448 9.75723 3.48217V2.66145C9.75723 2.01262 9.23672 1.48 8.57578 1.48H7.76475C7.45244 1.48 7.15224 1.35169 6.93193 1.1338L6.35331 0.555182C5.8909 0.0927714 5.15007 0.0927714 4.68524 0.555182L4.10662 1.1338C3.88631 1.35411 3.5861 1.48 3.2738 1.48H2.45308C1.80425 1.48 1.27163 2.00052 1.27163 2.66145V3.47249C1.27163 3.78479 1.14332 4.085 0.925427 4.30531L0.346808 4.88393C-0.115603 5.34634 -0.115603 6.08716 0.346808 6.552L0.925427 7.13061C1.14574 7.35092 1.27163 7.65113 1.27163 7.96344V8.78416C1.27163 9.43298 1.79214 9.9656 2.45308 9.9656H3.26411C3.57642 9.9656 3.87662 10.0939 4.09694 10.3118L4.67555 10.8904C5.13797 11.3528 5.87879 11.3528 6.34362 10.8904L6.92224 10.3118C7.14255 10.0915 7.44275 9.9656 7.75506 9.9656H8.57578C9.22461 9.9656 9.75723 9.44509 9.75723 8.78416V7.97312C9.75723 7.66081 9.88554 7.36061 10.1034 7.1403L10.6821 6.56168C11.1445 6.09927 11.1445 5.34634 10.6821 4.89361ZM5.51322 9.08194C3.65631 9.08194 2.15045 7.57608 2.15045 5.71917C2.15045 3.86227 3.65631 2.3564 5.51322 2.3564C7.37013 2.3564 8.87599 3.86227 8.87599 5.71917C8.87599 7.57608 7.37013 9.08194 5.51322 9.08194Z" fill="#091423" />
        <path d="M7.75265 10.4474C7.57107 10.4474 7.39192 10.5224 7.26119 10.6532L6.68257 11.2318C6.36784 11.5465 5.949 11.7208 5.50596 11.7208C5.06292 11.7208 4.64651 11.5465 4.32936 11.2318L3.75074 10.6532C3.62 10.5224 3.44085 10.4474 3.25927 10.4474H2.76297V13.3308C2.76297 13.6673 3.13823 13.8707 3.41906 13.6843L5.51322 12.3019L7.60255 13.6843C7.88338 13.8707 8.26106 13.6697 8.26106 13.3308V10.4474H7.75023H7.75265Z" fill="#091423" />
    </svg>
);

SvgGuarantee.defaultProps = defaultProps;

export default memo(SvgGuarantee);
