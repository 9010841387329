import { memo } from "react";

const defaultProps = {
  width: 21,
  height: 21,
};

const SvgCheck = ({ width, height }: typeof defaultProps) => (
  <svg width={width} height={height} viewBox="0 0 21 21" fill="none">
    <path d="M10.874 20.9658C16.3969 20.9658 20.874 16.4887 20.874 10.9658C20.874 5.44297 16.3969 0.96582 10.874 0.96582C5.35118 0.96582 0.874023 5.44297 0.874023 10.9658C0.874023 16.4887 5.35118 20.9658 10.874 20.9658Z" fill="#00AEC7" />
    <path d="M6.70737 11.7992L9.20737 14.2992L15.0407 8.46582" stroke="#0D1221" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

SvgCheck.defaultProps = defaultProps;

export default memo(SvgCheck);
