import * as React from "react";
import AppBar from "@mui/material/AppBar";
import {
  Box,
  IconButton,
  Toolbar,
  Typography,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import ListAltIcon from "@mui/icons-material/ListAlt";
import MenuIcon from "@mui/icons-material/Menu";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import Logout from "@mui/icons-material/Logout";
import { routes } from "../routes/routesPath";
import {
  SvgAbcd,
  SvgAboutUs,
  SvgBilling,
  SvgCourses,
  SvgDoctor,
  SvgDoctor1,
  SvgDrAi,
  SvgForum,
  SvgHome,
  SvgLab,
  SvgLegal,
  SvgLogoName,
  SvgProfile,
  SvgSettings,
  SvgSupport,
  SvgTerms,
  SvgVideo,
  SvgWorld,
} from "../icons";
import { RootState, resetStore, useAppDispatch } from "../redux/store";
import { UserDetails } from "../modules/ProfileModule/store/profile.types";
import { Toast } from "../packages";
import { getUserMiddleWare } from "../modules/ProfileModule/store/profileMiddleware";
import { isEmpty } from "../utils/validators";
import { getFileMiddleWare } from "../modules/MyLabModule/store/mylabMiddleware";
import { setLoader, setProfileUrl } from "./store/commonReducer";
import { logOut } from "../modules/SigninModule/store/signinReducer";
import { AUTH_TOKEN } from "../utils/localStoreConst";
import { BG_SHADES_LIGHTER, DARK } from "../theme/colors";
import { useLanguage } from "../lang/language";
import { isPaid, isTestosteronologistRole } from "../utils/helpers";
import { analyticsEvent } from "../utils/analyticsHelper";
import { ANALYTICS_EVENT } from "../utils/analyticsConstants";
import { FONTS } from "../utils/fontConstant";
// import SvgConnectTesto from "../icons/SvgConnectTesto";
import styles from "./responsivedrawer.module.scss";
import DrawerList from "./DrawerList";

const { ABCDS_TM, MY_HEALTH_TO_DO_LIST } = useLanguage;

export const drawerWidth = 275;

const focusColor = (val: boolean) => {
  return val ? "#35D7F3" : "#ffffff";
};

const settings = [
  {
    title: "Profile",
    path: routes.PROFILE,
    listItemIcon: (
      <Avatar
        sx={{ width: 24, height: 24, backgroundColor: BG_SHADES_LIGHTER }}
      />
    ),
  },
  {
    title: "Logout",
    path: "",
    listItemIcon: <Logout sx={{ width: 24, height: 24 }} />,
  },
];

const mapStateToProps = (state: RootState) => {
  const { getUserReducers, profileUrlReducers } = state;
  return {
    userDetails: getUserReducers.userDetails,
    profileUrl: profileUrlReducers.profileUrl,
  };
};

interface Props {
  children: React.ReactNode;
  userDetails: UserDetails;
  profileUrl: string;
}

const ResponsiveDrawer = (props: Props) => {
  const { children, userDetails } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentPath = location.pathname;
  const isPlan = userDetails?.userPlan === "free";
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleCloseUserMenu = (title: string, path: string) => {
    setAnchorEl(null);
    if (title === "Logout") {
      analyticsEvent({ eventName: ANALYTICS_EVENT.SIDE_NAV_LOG_OUT_BTN });
      Toast("Logout Successfully");
      localStorage.clear();
      navigate("/");
      resetStore();
      dispatch(logOut(null));
    } else {
      analyticsEvent({ eventName: ANALYTICS_EVENT.SIDE_NAV_PROFILE_BTN });
      navigate(path);
    }
  };

  useEffect(() => {
    const getToken = localStorage.getItem(AUTH_TOKEN);
    if (getToken) {
      dispatch(getUserMiddleWare()).then((getUserRes) => {
        if (!isEmpty(getUserRes.payload.userDetails?.profilePicture)) {
          dispatch(
            getFileMiddleWare({
              id: getUserRes.payload.userDetails?.profilePicture,
            })
          )
            .then((fileRes) => {
              if (fileRes.payload.presignedURL) {
                dispatch(setProfileUrl(fileRes.payload.presignedURL));
              }
            })
            .finally(() => dispatch(setLoader(false)));
        } else {
          dispatch(setLoader(false));
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(userDetails?.profilePicture)) {
      dispatch(
        getFileMiddleWare({
          id: userDetails.profilePicture,
        })
      )
        .then((fileRes) => {
          if (fileRes.payload.presignedURL) {
            dispatch(setProfileUrl(fileRes.payload.presignedURL));
          }
        })
        .finally(() => dispatch(setLoader(false)));
    }
  }, [userDetails]);

  const isShowCourses =
    isTestosteronologistRole(userDetails?.role) &&
    isPaid(userDetails?.userPlan);

  const pages = React.useMemo(() => {
    return [
      {
        title: "Home",
        path: routes.HOME,
        icons: <SvgHome focused={currentPath === routes.HOME} />,
        focus: currentPath === routes.HOME,
        subMenu: [
          {
            title: ABCDS_TM,
            path: routes.ABCDS,
            icons: (
              <SvgAbcd
                fill={
                  currentPath === routes.ABCDS ||
                  currentPath === routes.ABCDS_ADD ||
                  currentPath === routes.ABCDS_EDIT
                    ? "#35D7F3"
                    : "#fff"
                }
                focused={
                  currentPath === routes.ABCDS ||
                  currentPath === routes.ABCDS_ADD ||
                  currentPath === routes.ABCDS_EDIT
                }
              />
            ),
            focus:
              currentPath === routes.ABCDS ||
              currentPath === routes.ABCDS_ADD ||
              currentPath === routes.ABCDS_EDIT,
            eventName: ANALYTICS_EVENT.SIDE_NAV_ABCDS_BTN,
          },
          {
            title: MY_HEALTH_TO_DO_LIST,
            path: routes.TODO,
            icons: (
              <ListAltIcon
                sx={{
                  color: currentPath === routes.TODO ? "#35D7F3" : "#fff",
                  opacity: currentPath === routes.TODO ? 1 : 0.5,
                  height: 24,
                  width: 24,
                }}
              />
            ),
            focus: currentPath === routes.TODO,
            eventName: ANALYTICS_EVENT.SIDE_NAV_MY_HEALTH_TO_DO_LIST_BTN,
          },
        ],
        newTab: false,
        show: true,
        eventName: ANALYTICS_EVENT.SIDE_NAV_HOME_BTN,
      },
      {
        title: "Labs",
        path: routes.LABS,
        icons: <SvgLab focused={currentPath === routes.LABS} />,
        focus: currentPath === routes.LABS,
        subMenu: [],
        newTab: false,
        show: true,
        eventName: ANALYTICS_EVENT.SIDE_NAV_LABS_BTN,
      },
      {
        title: "Forums",
        path: routes.FORUM,
        icons: <SvgForum focused={currentPath === routes.FORUM} />,
        focus: currentPath === routes.FORUM,
        subMenu: [],
        newTab: !isPlan,
        show: true,
        eventName: ANALYTICS_EVENT.SIDE_NAV_FORUMS_BTN,
      },
      // {
      //   title: "Connection To Your Testo",
      //   path: routes.CONNECT_YOUR_TESTO,
      //   icons: (
      //     <SvgConnectTesto
      //       focused={currentPath === routes.CONNECT_YOUR_TESTO}
      //       fill={focusColor(currentPath === routes.CONNECT_YOUR_TESTO)}
      //     />
      //   ),
      //   focus: currentPath === routes.CONNECT_YOUR_TESTO,
      //   subMenu: [],
      //   newTab: false,
      //   show: true,
      //   eventName: ANALYTICS_EVENT.SIDE_NAV_CONNECT_TESTO_BTN,
      // },
      {
        title: "Find a Testosteronologist™",
        path: routes.FIND_A_PROVIDER,
        icons: (
          <SvgWorld
            focused={currentPath === routes.FIND_A_PROVIDER}
            fill={focusColor(currentPath === routes.FIND_A_PROVIDER)}
          />
        ),
        focus: currentPath === routes.FIND_A_PROVIDER,
        subMenu: [],
        newTab: false,
        show: true,
        eventName: ANALYTICS_EVENT.SIDE_NAV_FIND_A_PROVIDER_BTN,
      },
      {
        title: "Become a Testosteronologist™",
        path: routes.BECOME_A_PROVIDER,
        icons: (
          <SvgDoctor1
            focused={currentPath === routes.BECOME_A_PROVIDER}
            fill={focusColor(currentPath === routes.BECOME_A_PROVIDER)}
          />
        ),
        focus: currentPath === routes.BECOME_A_PROVIDER,
        subMenu: [],
        newTab: false,
        show: true,
        eventName: ANALYTICS_EVENT.SIDE_NAV_BECOME_A_PROVIDER_BTN,
      },
      // {
      //   title: "Your Courses",
      //   path: process.env.REACT_APP_YOUR_COURSES,
      //   icons: (
      //     <SvgCourses
      //       focused={currentPath === routes.BECOME_A_PROVIDER}
      //       fill={focusColor(currentPath === routes.BECOME_A_PROVIDER)}
      //     />
      //   ),
      //   focus: "",
      //   subMenu: [],
      //   newTab: true,
      //   show: isShowCourses,
      //   eventName: ANALYTICS_EVENT.SIDE_NAV_YOUR_COURSES_BTN,
      // },
      {
        title: "Videos",
        path: routes.VIDEOS,
        icons: <SvgVideo focused={currentPath === routes.VIDEOS} />,
        focus: currentPath === routes.VIDEOS,
        subMenu: [],
        newTab: false,
        show: true,
        eventName: ANALYTICS_EVENT.SIDE_NAV_VIDEO_BTN,
      },
      {
        title: "Ask Dr. O'Connor GPT",
        path: routes.DRAI,
        icons: (
          <SvgDrAi
            height={26}
            width={26}
            fill={focusColor(currentPath === routes.DRAI)}
            focused={currentPath === routes.DRAI}
          />
        ),
        focus: currentPath === routes.DRAI,
        subMenu: [],
        newTab: false,
        show: true,
        eventName: ANALYTICS_EVENT.SIDE_NAV_ASK_DR_O_GPT_BTN,
      },
      {
        title: "Ask The Doc Live",
        path: routes.ASK_THE_DOC,
        icons: <SvgDoctor focused={currentPath === routes.ASK_THE_DOC} />,
        focus: currentPath === routes.ASK_THE_DOC,
        subMenu: [],
        newTab: false,
        show: true,
        eventName: ANALYTICS_EVENT.SIDE_NAV_ASK_THE_DOC_LIVE_BTN,
      },
    ];
  }, [currentPath, isShowCourses]);

  const page_1 = React.useMemo(() => {
    return [
      {
        title: "Profile",
        path: routes.PROFILE,
        icons: (
          <SvgProfile
            focused={currentPath === routes.PROFILE}
            fill={focusColor(currentPath === routes.PROFILE)}
          />
        ),
        focus: currentPath === routes.PROFILE,
        newTab: false,
        subMenu: [],
        show: true,
        eventName: ANALYTICS_EVENT.SIDE_NAV_PROFILE_BTN,
      },
      {
        title: "Billing",
        path: routes.BILLING,
        icons: (
          <SvgBilling
            focused={currentPath === routes.BILLING}
            fill={focusColor(currentPath === routes.BILLING)}
          />
        ),
        focus: currentPath === routes.BILLING,
        newTab: false,
        subMenu: [],
        show: true,
        eventName: ANALYTICS_EVENT.SIDE_NAV_PREMIMUM_BTN,
      },
      {
        title: "User Settings",
        path: routes.SETTINGS,
        icons: (
          <SvgSettings
            focused={currentPath === routes.SETTINGS}
            fill={focusColor(currentPath === routes.SETTINGS)}
          />
        ),
        focus: currentPath === routes.SETTINGS,
        newTab: false,
        subMenu: [],
        show: true,
        eventName: ANALYTICS_EVENT.SIDE_NAV_USER_SETTINGS_BTN,
      },
      {
        title: "Support",
        path: routes.SUPPORT,
        icons: (
          <SvgSupport
            focused={currentPath === routes.SUPPORT}
            fill={focusColor(currentPath === routes.SUPPORT)}
          />
        ),
        focus: currentPath === routes.SUPPORT,
        newTab: false,
        subMenu: [],
        show: true,
        eventName: ANALYTICS_EVENT.SIDE_NAV_SUPPORT_BTN,
      },
      {
        title: "About",
        path: routes.ABOUT_US,
        icons: (
          <SvgAboutUs
            focused={currentPath === routes.ABOUT_US}
            fill={focusColor(currentPath === routes.ABOUT_US)}
          />
        ),
        focus: currentPath === routes.ABOUT_US,
        newTab: true,
        subMenu: [],
        show: true,
        eventName: ANALYTICS_EVENT.SIDE_NAV_ABOUT_BTN,
      },
      {
        title: "Legal",
        path: "",
        icons: (
          <SvgLegal
            focused={currentPath === routes.TERMS_OF_SERVICE}
            fill={focusColor(currentPath === routes.TERMS_OF_SERVICE)}
          />
        ),
        focus: currentPath === routes.TERMS_OF_SERVICE,
        newTab: true,
        show: true,
        eventName: ANALYTICS_EVENT.SIDE_NAV_LEGAL_BTN,
        subMenu: [
          {
            title: "Privacy Policy",
            path: routes.PRIVACY_POLICY,
            icons: (
              <PrivacyTipIcon
                sx={{
                  color: focusColor(currentPath === routes.PRIVACY_POLICY),
                  opacity: currentPath === routes.PRIVACY_POLICY ? 1 : 0.5,
                }}
              />
            ),
            focus: currentPath === routes.PRIVACY_POLICY,
            newTab: true,
            show: true,
            eventName: ANALYTICS_EVENT.SIDE_NAV_PRIVACY_POLICY_BTN,
          },
          {
            title: "Terms of Service",
            path: routes.TERMS_OF_SERVICE,
            icons: (
              <SvgTerms
                focused={currentPath === routes.TERMS_OF_SERVICE}
                fill={focusColor(currentPath === routes.TERMS_OF_SERVICE)}
              />
            ),
            focus: currentPath === routes.TERMS_OF_SERVICE,
            newTab: true,
            show: true,
            eventName: ANALYTICS_EVENT.SIDE_NAV_TERMS_OF_SERVICE_BTN,
          },
        ],
      },
    ];
  }, [currentPath]);

  return (
    <Box className={styles.overAll} sx={{ display: "flex" }}>
      <Box className={styles.mobileBox}>
        <AppBar
          position="fixed"
          className={styles.appBar}
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            pr: 4,
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon style={{ width: 45, height: 45 }} />
            </IconButton>
            <Box
              onClick={() => navigate("/")}
              sx={{ mr: 1, cursor: "pointer" }}
            >
              <SvgLogoName height={60} width={200} />
            </Box>
          </Toolbar>

          <Box sx={{ flexGrow: 0 }}>
            <IconButton
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={{ p: 0 }}
            >
              <Avatar
                sx={{ backgroundColor: "white" }}
                alt=""
                src={
                  isEmpty(props.profileUrl)
                    ? "/broken-image.jpg"
                    : props.profileUrl
                }
              />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {settings.map((setting) => (
                <MenuItem
                  sx={{ fontFamily: FONTS.SOURCESANSPRO_SEMIBOLD }}
                  key={setting.title}
                  onClick={() =>
                    handleCloseUserMenu(setting.title, setting.path)
                  }
                >
                  <ListItemIcon>{setting.listItemIcon}</ListItemIcon>
                  {setting.title}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </AppBar>
        <Box
          component="nav"
          sx={{
            width: { sm: drawerWidth },
            flexShrink: { sm: 0 },
          }}
          aria-label="mailbox folders"
        >
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onTransitionEnd={handleDrawerTransitionEnd}
            onClose={handleDrawerClose}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                backgroundColor: DARK,
              },
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <DrawerList
              handleDrawerClose={handleDrawerClose}
              navigate={navigate}
              page_1={page_1}
              // @ts-ignore
              pages={pages}
              currentPath={currentPath}
            />
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                backgroundColor: DARK,
              },
            }}
            open
          >
            <DrawerList
              handleDrawerClose={handleDrawerClose}
              navigate={navigate}
              page_1={page_1}
              // @ts-ignore
              pages={pages}
              currentPath={currentPath}
            />
          </Drawer>
        </Box>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            height: window.innerHeight,
            overflowY: "scroll",
          }}
        >
          <Toolbar />
          {children}
        </Box>
      </Box>
      <Box className={styles.desktopBox}>
        <Box
          className={styles.listBox}
          sx={{
            height: window.innerHeight,
          }}
        >
          <DrawerList
            handleDrawerClose={handleDrawerClose}
            navigate={navigate}
            page_1={page_1}
            // @ts-ignore
            pages={pages}
            currentPath={currentPath}
          />
        </Box>

        <Box className={styles.topNavContainer}>
          <Box className={styles.topNav} sx={{ px: 2 }}>
            <Box onClick={() => navigate("/")} sx={{ cursor: "pointer" }}>
              <SvgLogoName height={60} width={250} />
            </Box>

            <IconButton
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={{ p: 0 }}
            >
              <Avatar
                sx={{ backgroundColor: "white" }}
                alt=""
                src={
                  isEmpty(props.profileUrl)
                    ? "/broken-image.jpg"
                    : props.profileUrl
                }
              />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {settings.map((setting) => (
                <MenuItem
                  sx={{ fontFamily: FONTS.SOURCESANSPRO_SEMIBOLD }}
                  key={setting.title}
                  onClick={() =>
                    handleCloseUserMenu(setting.title, setting.path)
                  }
                >
                  <ListItemIcon>{setting.listItemIcon}</ListItemIcon>
                  <Typography textAlign="center">{setting.title}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box
            className={styles.childrenBox}
            sx={{
              height: window.innerHeight - 100,
              // width: window.innerWidth - drawerWidth,
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default connect(mapStateToProps)(ResponsiveDrawer);
